<template>
    <div>body 2</div>
</template>

<script>
    export default {
        name: "body2"
    }
</script>

<style scoped>

</style>
